<template>
  <section
    :class="[colorVariant]"
    class="home-block"
    v-bind="{ ...$props, ...$attrs }"
  >
    <div class="container">
      <div :class="{ horizontal }">
        <h2 v-if="title" class="home-block__title">
          {{ title }}
        </h2>
        <div
          :class="{ 'home-block__content--has-title': title }"
          class="home-block__content"
        >
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'HomeBlock',

  mixins: [usesColor],

  props: {
    title: {
      type: String,
      default: ''
    },

    horizontal: {
      type: Boolean,
      default: false
    }
  }
}
</script>
